
<template>
  <!--Header-->
    <div class="pt-12 grid justify-items-stretch">
        <div class="justify-self-center relative"> 
          <h1 class="text-3xl font-bold z-10 relative dark:text-gray-200">{{ title }}</h1>
          <span class="absolute w-120 h-3 bg-yellow-100 bottom-0.5 -ml-10 dark:bg-gray-600"></span>
      </div>
    </div>
    
    <!-- Who we are -->
    
    <div class="pt-8 container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div class="hidden md:flex flex flex-col w-full lg:w-3/12 xl:w-2/12 py-1 pr-2 justify-start items-start">
            <img :src="image" alt="img" class="w-20 md:w-40 lg:w-40">
        </div>
        <div class="flex flex-col w-full lg:w-9/12 xl:w-8/12 justify-center items-start">
               <div class="font-light text-dark dark:text-white content" v-html="content">
              </div>
        </div>    
    </div>
    
  
  

</template>

<script>
export default {
  name: 'ContentDetail',
  props: ['title', 'content', 'image',]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="postcss">

.content ul, ol {
  @apply list-disc list-inside;
}
.content li {
  @apply pl-2;
}
.content h1, h2 {
  @apply text-xl font-bold dark:text-gray-200 pb-2;
}
.content p {
  @apply pb-2;
}
.content b {
  font-weight: bold;
}
.process-icons {
    font-size: 44px;
}
</style>

<template>
  <main>
    <div v-if="loading" class="h-screen flex justify-center items-center">
      <div style="border-top-color:transparent"
        class="w-16 h-16 border-4 border-green-400 border-dotted rounded-full animate-spin"></div>
    </div>
    <div v-else>
      <HeroDetail :green_title="service[0].hero_green_title" :black_title="service[0].hero_black_title" :content="service[0].hero_content" :links="service[0].hero_links" :image="service[0].main_image" />
      <ContentDetail :title="service[0].parts_title" :parts="service[0].service_parts" />
      <TechStackDetail :title="service[0].tech_title" :content="service[0].tech_content" :image="service[0].tech_img" :subtitle="service[0].tech_subtitle" :icons="service[0].tech_icons" />
      <FeaturedProject :portfolio="portfolio[0]" />
    <EstimateProject />
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import HeroDetail from '@/components/Shared/HeroDetail.vue'
import ContentDetail from '@/components/Services/ContentDetail.vue'
import TechStackDetail from '@/components/Services/TechStackDetail.vue'
import FeaturedProject from '@/components/Shared/FeaturedProject.vue'
import EstimateProject from '@/components/Shared/EstimateProject.vue'

import serverAddress from '@/mixins/serverAddress';
import titleMixin from '@/mixins/titleMixin'
import descriptionMixin from '@/mixins/descriptionMixin'

export default {
  // title on created()
  name: 'Service',
  description: "Mobile app development, web apps, ux design services. Native and crossplatform applications for iOS and Android", 
  mixins: [serverAddress, titleMixin, descriptionMixin],
  components: {
    HeroDetail,
    ContentDetail,
    TechStackDetail,
    FeaturedProject,
    EstimateProject
  },
  data() {
    return {
      loading: true,
      service: [],
      portfolio: [],
      serviceTitle: ''
    }
  },
  methods: {
    async getServices() {
      const res = await fetch(this.serverAddress + '/api/v2/pages/?type=services.Service&fields=*&slug=' + this.$route.params.slug)
      const data = await res.json()
      return data
    },
    async getPortfolio() {
      // random and featured
      const res = await fetch(this.serverAddress + '/api/v2/pages/?type=portfolio.Portfolio&fields=*&order=random&limit=1&featured=true')
      const data = await res.json()
      return data
    },
  },
  async created() {
    const data = await this.getServices()
    this.service = data.items

    const portfolioData = await this.getPortfolio()
    this.portfolio = portfolioData.items

    this.loading = false
    // title
    document.title = `${ this.service[0].title } | Flexisoft`
    document.querySelector('meta[name="description"]').setAttribute("content", this.service[0].meta.search_description );
  }
}
// http://localhost:8000/api/v2/pages/?type=services.Service&fields=*&slug=mobile-application-development
</script>

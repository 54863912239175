
<template>
    <div class="pt-12 grid justify-items-stretch">
        <div class="justify-self-center relative"> 
          <h1 class="text-3xl font-bold z-10 relative dark:text-gray-200">{{ header }}</h1>
          <span class="absolute w-120 h-3 bg-yellow-100 bottom-0.5 -ml-10 dark:bg-gray-600"></span>
      </div>
    </div>
    

    
    <div class="pt-8 container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-start">
        <div class="hidden md:flex flex flex-col w-full md:w-2/12 py-1 justify-start items-start">
            <img :src="image" alt="img" class="w-20 md:w-40 lg:w-40">
        </div>
        <div class="flex flex-col w-full md:w-8/12 justify-center items-start">
               <p class="font-light text-dark dark:text-white" v-html="content"></p>

               <div class="flex flex-col w-full pt-4">
                <div v-for="contact in contacts" :key="contact"  class="flex flex-row flex-wrap justify-center">
                  <div class="flex flex-row w-full items-center justify-start mb-8">
                    <span class="w-2/12 md:w-1/12 material-icons-outlined text-base text-mygreen pr-8 text-center md:text-right">{{ contact.value.icon }}</span>
                    <div class="w-10/12 md:w-11/12 mt-2 pl-2">
                      <h3 class="text-dark text-xl  text-dark font-bold dark:text-gray-300"><span class="text-mygreen">{{ contact.value.header }}</span>

                        <br v-if="contact.value.email">{{ contact.value.email }}
                      </h3>
                      <p v-if="contact.value.text" class="text-dark dark:text-gray-300">{{ contact.value.text }}</p>
                    </div>
                  </div>            
                </div>
            </div>
            
          
        </div>    
    </div>
    
  
  

</template>

<script>
export default {
  name: 'ContentDetail',
  props: [ 'header', 'image', 'content', 'contacts' ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.material-icons-outlined {
    font-size: 40px;
}
</style>

<template>
  <div class="pt-6"></div>
  <div v-for="(portfolio, index) in portfolio_list" :key="index">
    <div class="pt-8 grid justify-items-stretch">
        <div class="justify-self-center relative"> 
          <h1 class="text-3xl font-bold z-10 relative dark:text-gray-200">{{ portfolio.title }}</h1>
          <span class="absolute w-120 h-3 bg-yellow-100 bottom-0.5 -ml-10 dark:bg-gray-600"></span>
      </div>
    </div>
    <div class="pt-8">
      <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-start">
        
        <div class="flex flex-col w-full md:w-9/12 justify-center items-start">
          <div>
            <!-- <b class="text-mygreen font-bold">{{ portfolio.title }}</b>  -->
              <p class="font-light text-dark portfolio-content dark:text-white" v-html="portfolio.content"></p>
              
              <div class="flex flex-col w-full pt-3">  
                <b class="text-mygreen">{{ portfolio.parts_title }}</b>
                
                <div class="flex flex-col md:flex-row md:space-x-6 pt-2">
                    <div v-for="part in portfolio.parts_icons" :key="part" class="flex items-center">
                        <span class="material-icons-outlined text-base text-mygreen">{{ part.value.icon}}</span>
                        <!-- <span class="font-normal text-mygreen">{{ part.value.green_header}}</span>&nbsp;<span class="font-normal"> {{ part.value.black_header}}</span> -->
                        <span class="font-normal dark:text-white"> {{ part.value.black_header}}</span>
                    </div>
                </div>
              </div>
              
              <div class="hidden md:flex justify-end pt-6">
                  <button class="flex items-center transform transition hover:scale-105 duration-200">
                      <a class="font-semibold text-mygreen dark:text-green-500" :href="'portfolio/' + portfolio.meta.slug">learn more</a>
                      <span class="material-icons-outlined text-base text-mygreen dark:text-green-500">arrow_right_alt</span>
                  </button>
              </div>
          </div>
        </div>
        <div class="flex flex-col w-full md:w-3/12 pt-2 justify-center items-center">
            <a :href="'portfolio/' + portfolio.meta.slug">
              <img :src="portfolio.project_img" alt="img" class="cursor-pointer transform transition hover:scale-105 duration-200">
            </a>
        </div>
        <div class="flex md:hidden w-full justify-end pt-6">
                  <button class="flex items-center transform transition hover:scale-105 duration-200">
                      <a class="font-semibold text-mygreen dark:text-green-500" :href="'portfolio/' + portfolio.meta.slug">learn more</a>
                      <span class="material-icons-outlined text-base text-mygreen dark:text-green-500">arrow_right_alt</span>
                  </button>
        </div>
      </div>
    </div>
  </div>
       

</template>

<script>
export default {
  name: 'List',
  props: [ 'portfolio_list', ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
  <!--Header-->
    <div class="pt-12 pb-8 grid justify-items-stretch">
      <!-- <div class="container px-3 mx-auto flex justify-center relative "> -->
        <div class="justify-self-center relative"> 
          <h1 class="text-3xl font-bold z-10 relative dark:text-gray-300">{{ title }}</h1>
          <span class="absolute w-120 h-3 bg-yellow-100 bottom-0.5 -ml-10 dark:bg-gray-600"></span>
      </div>
    </div>
    
    <div v-for="(part, index) in parts" :key="index">
      <div v-if="index == 0">
        <Card side="left" :title="part.title" :content="part.content" :img="part.main_image" />
      </div>
      <div v-else-if="index == 1">
        <Card side="right" :title="part.title" :content="part.content" :img="part.main_image" />
      </div>
      <div v-else-if="index == 2">
        <Card side="left2" :title="part.title" :content="part.content" :img="part.main_image" />
      </div>
      <div v-else>
        <Card side="right2" :title="part.title" :content="part.content" :img="part.main_image" />
      </div>
    </div>


</template>

<script>
import Card from '@/components/Shared/Card.vue'
export default {
  name: 'Contentpart',
  props: [ 'title', 'parts' ],
  components: {
    Card,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

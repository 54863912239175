<template>
  <main>
    <div v-if="loading" class="h-screen flex justify-center items-center">
      <div style="border-top-color:transparent"
        class="w-16 h-16 border-4 border-green-400 border-dotted rounded-full animate-spin"></div>
    </div>
    <div v-else>    
      <HeroDetail :green_title="portfolio[0].hero_green_title" :black_title="portfolio[0].hero_black_title" :content="portfolio[0].hero_content" :links="portfolio[0].hero_links" :image="portfolio[0].main_image" />
      <ContentDetail :content="portfolio[0].content" :image="portfolio[0].main_image" :project_img="portfolio[0].project_img" :tech_title="portfolio[0].tech_title" :tech_icons="portfolio[0].tech_icons" :portfolio_process="portfolio[0].portfolio_process" />
      <EstimateProject />
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import HeroDetail from '@/components/Shared/HeroDetail.vue'
// import Hero from '@/components/Portfolio/HeroDetail.vue'
import ContentDetail from '@/components/Portfolio/ContentDetail.vue'
import EstimateProject from '@/components/Shared/EstimateProject.vue'

import serverAddress from '@/mixins/serverAddress';


export default {
  name: 'Portfolio',
  mixins: [serverAddress],
  components: {
    HeroDetail,
    ContentDetail,
    EstimateProject
  },
  data() {
    return {
      loading: true,
      portfolio: [],
    }
  },
  methods: {
    async getServices() {
      const res = await fetch(this.serverAddress + '/api/v2/pages/?type=portfolio.Portfolio&fields=*&slug=' + this.$route.params.slug)
      const data = await res.json()
      return data
    },
  },
  async created() {
    const data = await this.getServices()
    this.portfolio = data.items
    
    this.loading = false

    document.title = `${ this.portfolio[0].title } portfolio | Flexisoft`
    document.querySelector('meta[name="description"]').setAttribute("content", this.portfolio[0].meta.search_description );
  }
}
</script>

<template>
  <main>
    <div v-if="loading" class="h-screen flex justify-center items-center">
      <div style="border-top-color:transparent"
        class="w-16 h-16 border-4 border-green-400 border-dotted rounded-full animate-spin"></div>
    </div>
    <div v-else>
      <HeroDetail :green_title="page[0].hero_green_title" :black_title="page[0].hero_black_title" :content="page[0].hero_content" :links="page[0].hero_links" :image="page[0].main_image" />
      <ContentDetail :title="page[0].title" :content="page[0].content" :image="page[0].logo_img" />
      <EstimateProject />
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import HeroDetail from '@/components/Shared/HeroDetail.vue'
import ContentDetail from '@/components/StaticPage/ContentDetail.vue'
import EstimateProject from '@/components/Shared/EstimateProject.vue'

import serverAddress from '@/mixins/serverAddress';
import titleMixin from '@/mixins/titleMixin'

export default {
  title: 'Mobile application development company  | Flexisoft',
  name: 'StaticPage',
  mixins: [serverAddress, titleMixin],
  components: {
    HeroDetail,
    ContentDetail,
    EstimateProject 
  },
  data() {
    return {
      loading: true,
      page: [],
    }
  },
  methods: {
    async getPage() {
      const res = await fetch(this.serverAddress + '/api/v2/pages/?type=staticpages.StaticPage&fields=*&slug=' + this.$route.params.slug)
      const data = await res.json()
      return data
    },
    
  },
  async created() {
    const data = await this.getPage()
    this.page = data.items

    this.loading = false

    document.querySelector('meta[name="description"]').setAttribute("content", this.page[0].meta.search_description );
  }
}
</script>

<template>
   <!--Header-->
    <div class="pt-14 grid justify-items-stretch">
      <!-- <div class="container px-3 mx-auto flex justify-center relative "> -->
        <div class="justify-self-center relative"> 
          <h1 class="text-3xl font-bold relative z-10 dark:text-gray-200">About the project</h1>
          <span class="absolute w-120 h-3 bg-yellow-100 bottom-0.5 -ml-10 dark:bg-gray-600"></span>
      </div>
    </div>
    <!-- Card item -->
    <div class="pt-8">
      <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-start">
        <!--Left Col-->
        <div class="flex flex-col w-full md:w-9/12 justify-center items-start">
          <div class="">
              <p class="font-light text-dark portfolio-content dark:text-white" v-html="content">
              </p>
              <div class="flex flex-col w-full pt-3">
                <h3 class="text-mygreen text-xl font-bold">{{ tech_title }}</h3>
                
                 <div class="flex flex-col md:flex-row flex-wrap">
                    <div v-for="icon in tech_icons" :key="icon" class="flex items-center">
                        <span class="w-2/12 material-icons-outlined text-base text-dark pr-8 dark:text-gray-300">{{ icon.value.icon }}</span>
                        <h3 class="w-10/12 my-2 text-lg  text-dark font-bold pl-2 dark:text-gray-300">
                            <span class="text-mygreen">{{ icon.value.green_header }}</span><br>{{ icon.value.black_header }}
                        </h3>
                    </div>
                </div>
              </div>
              
          </div>
        </div>
        
        <div class="flex flex-col w-full md:w-3/12 justify-center items-center">
            <img :src="project_img" alt="img" class="cursor-pointer transform transition hover:scale-105 duration-200">
        </div>
        <div class="flex md:hidden w-full justify-end pt-6">
                  <button class="flex items-center transform transition hover:scale-105 duration-200">
                      <p class="font-semibold text-mygreen">learn more</p>
                      <span class="material-icons-outlined text-base text-mygreen">arrow_right_alt</span>
                  </button>
        </div>
      </div>

      
      <div class="pt-14 pb-10 grid justify-items-stretch">
        <!-- <div class="container px-3 mx-auto flex justify-center relative "> -->
          <div class="justify-self-center relative"> 
            <h1 class="text-3xl font-bold dark:text-gray-200 relative z-10">Dev process</h1>
            <span class="absolute w-120 h-3 bg-yellow-100 bottom-0.5 -ml-10 dark:bg-gray-600"></span>
        </div>
      </div>

      <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-start">
        
        <div v-for="(process, index) in portfolio_process" :key="index" class="flex flex-col w-full md:w-6/12 lg:w-3/12 justify-start items-center px-3 pb-3 lg:pb-0">
          <div class="rounded h-80 relative shadow-lg cursor-pointer hover:opacity-90" @click="openModal(process.main_image, process.content)">
          <!-- <div class="rounded h-80 relative shadow-lg cursor-pointer hover:opacity-90" @click="openModal"> -->
              <img :src="process.thumbnail" :alt="process.title" class="rounded object-cover w-full h-full">
              <div class="absolute w-full px-2 bottom-0 left-0 text-center text-mygreen bg-white pt-2 pb-3">
                <h3 class="text-lg font-bold">{{ process.title }}</h3>
              <p class="font-light text-dark px-1">{{ process.subtitle }}</p>
              </div>
          </div>
        </div>
         
      </div>

    </div>

    <div @click="closeModal" id="modal"
    class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-gray-800 z-50 bg-opacity-50 transform scale-0 transition-transform duration-300">
    <!-- Modal content -->
    <div class="bg-white w-12/12 lg:w-8/12 h-12/12 lg:h-6/12 p-4 lg:p-12"> 
        <!--Close modal button-->
        <button id="closebutton" type="button" class="focus:outline-none" @click="closeModal">
          <span class="material-icons-outlined">close</span>      
       </button>
        <!-- Test content -->
        <img :src="modalImage" alt="mobile" class="rounded w-full h-full">
        <p v-html="modalText"></p>
    </div>
</div>
    

</template>

<script>
export default {
  name: 'ContentDetail',
  props: [ 'content', 'image', 'project_img', 'tech_title', 'tech_icons', 'portfolio_process' ],
  data() {
    return {
      modalImage: '',
      modalText: ''
    }
  },
  methods: {
    openModal(img, text) {
      const modal = document.getElementById('modal')
      this.modalImage = img
      this.modalText = text
      modal.classList.add('scale-100');
    },
    // openModal() {
    //   const modal = document.getElementById('modal')
    //   modal.classList.add('scale-100');
    // },
    closeModal() {
      const modal = document.getElementById('modal')
      modal.classList.remove('scale-100');
    },
    
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.portfolio-content a { 
  text-decoration: none;
  font-weight: bolder;
  color: rgba(93, 161, 39, 1);
}
.material-icons-outlined {
    font-size: 36px;
}

/* .green-bold b {
  @apply font-bold text-mygreen;
} */
</style>

<template>
<!--Hero-->
    <div class="pt-32 sm:pt-40">
      <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <!--Left Col-->
        <div class="flex flex-col w-full md:w-5/12 justify-center items-start text-center md:text-left">
          <h1 class="mb-5 text-3xl lg:text-5xl font-extrabold leading-tight dark:text-gray-300">
            <span class="text-transparent bg-clip-text bg-gradient-to-br from-green-500 to-green-800 dark:from-green-500 dark:to-green-600">{{ green_title }}</span> {{ black_title }}
          </h1>
          <p class="leading-normal mb-5 text-gray-500 dark:text-gray-300" v-html="content"></p>
          <div class="flex flex-col w-full lg:flex-row justify-center items-center">
            <a v-for="(link, index) in links" :key="index" :href="link.value.url" class="w-full">
              <button 
                :class="[index == 0 ? 'w-full mx-auto lg:mx-0 bg-white text-gray-800 dark:bg-gray-300' : 'w-full mx-auto lg:mx-2 bg-green-500 text-white', 'font-bold rounded-full my-2 py-2 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:none hover:scale-105 duration-300 ease-in-out']"
              >
                {{ link.value.name }}
              </button>
            </a>
          
          </div>
          
        </div>
        <div class="w-2/12"></div>
        <!--Right Col-->
        <div class="flex flex-col w-full md:w-5/12 pt-8 md:pt-0 justify-center md:justify-end items-center md:items-end">
            <img :src="image" alt="img" class="w-72 md:w-auto animate-fade-in-up">
        </div>
        <!-- <div class="w-full md:w-6/12 pl-8 py-6 flex justify-end items-center -z-10 ">      
        
          
        </div> -->
      </div>
    </div>
</template>

<script>
export default {
  name: 'HeroService',
  props: [ 'green_title', 'black_title', 'content', 'links', 'image'],
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
svg#freepik_stories-programming:not(.animated) .animable {opacity: 0;}svg#freepik_stories-programming.animated #freepik--Desk--inject-41 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideLeft;animation-delay: 0s;}svg#freepik_stories-programming.animated #freepik--Character--inject-41 {animation: 0.8s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideUp;animation-delay: 0s;}svg#freepik_stories-programming.animated #freepik--speech-bubble--inject-41 {animation: 1s 1 forwards cubic-bezier(.36,-0.01,.5,1.38) slideUp;animation-delay: 0s;}            @keyframes slideLeft {                0% {                    opacity: 0;                    transform: translateX(-30px);                }                100% {                    opacity: 1;                    transform: translateX(0);                }            }                    @keyframes slideUp {                0% {                    opacity: 0;                    transform: translateY(30px);                }                100% {                    opacity: 1;                    transform: inherit;                }            }        .animator-hidden { display: none; }
</style>


<template>
  <!--Header-->
    <div class="pt-12 grid justify-items-stretch">
      <!-- <div class="container px-3 mx-auto flex justify-center relative "> -->
        <div class="justify-self-center relative"> 
          <h1 class="text-3xl font-bold z-10 relative dark:text-gray-200">Who we are</h1>
          <span class="absolute w-120 h-3 bg-yellow-100 bottom-0.5 -ml-10 dark:bg-gray-600"></span>
      </div>
    </div>
    
    <!-- Who we are -->
    
    <div class="pt-8 container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div class="hidden md:flex flex flex-col w-full lg:w-3/12 xl:w-2/12 py-1 pr-2 justify-start items-start">
            <img :src="image" alt="img" class="w-20 md:w-40 lg:w-40">
        </div>
        <div class="flex flex-col w-full lg:w-9/12 xl:w-8/12 justify-center items-start">
               <p class="font-light text-dark dark:text-white" v-html="content">
              </p>
              <div class="flex flex-col w-full pt-3">
                <b class="text-mygreen dark:text-gray-300">{{ techTitle }}</b>
                
                <div class="flex flex-col md:flex-row md:space-x-6 pt-2">
                    <div v-for="part in techIcons" :key="part" class="flex items-center">
                        <span class="material-icons-outlined text-base text-mygreen">{{ part.value.icon}}</span>
                        <span class="font-normal dark:text-gray-300"> {{ part.value.header }}</span>
                    </div>
                </div>
              </div>
              
          
        </div>    
    </div>
    
  <!-- Process -->
    <div class="pt-12 grid justify-items-stretch">
        <div class="justify-self-center relative"> 
          <h1 class="text-3xl font-bold relative dark:text-gray-200 z-10">{{ processTitle }}</h1>
          <span class="absolute w-120 h-3 bg-yellow-100 bottom-0.5 -ml-10 dark:bg-gray-600"></span>
      </div>
    </div>
    
    <div class="flex flex-col w-full pt-4">
      <div class="flex flex-row flex-wrap justify-center">

        <div v-for="(process, index) in processIcons" :key="index" class="flex flex-row w-full items-center justify-center mb-8">
          
          <span :class="[index % 2 == 0 ? 'text-mygreen': 'text-dark dark:text-gray-300', 'w-2/12 md:w-2/12 material-icons-outlined process-icons text-base pr-8 text-center md:text-right']">{{ process.value.icon}}</span>
          <div class="w-10/12 md:w-8/12 mt-2 pl-2">
            <h3 :class="[index % 2 == 0 ? 'text-mygreen': 'text-dark dark:text-gray-300', 'text-2xl font-bold']">{{ process.value.header }}</h3>
           <p class="font-light text-dark dark:text-gray-300" v-html="process.value.content"></p>
          </div>
          <div class="hidden md:flex md:w-2/12"></div>
        </div>           
                </div>          
         </div>


    <!-- <div class="pt-12 grid justify-items-stretch">
        <div class="justify-self-center relative"> 
          <h1 class="text-3xl font-bold z-50 dark:text-gray-200">Our team?</h1>
          <span class="absolute w-120 h-3 bg-yellow-100 bottom-0.5 -ml-10 -z-10"></span>
      </div>
    </div> -->
  

</template>

<script>
export default {
  name: 'ContentDetail',
  props: [ 'content', 'image', 'techTitle', 'techIcons', 'processTitle', 'processIcons' ]
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.process-icons {
    font-size: 44px;
}
</style>

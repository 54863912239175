function getDescription (vm) {
    const { description } = vm.$options
    if (description) {
      return typeof description === 'function'
        ? description.call(vm)
        : description
    }
}

export default {
    created () {
        const description = getDescription(this)
        if (description) {
            document.querySelector('meta[name="description"]').setAttribute("content", description);
            // document.description = description
            // console.log(description);
        }
    }
}
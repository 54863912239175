<template>
  <main>
    <div v-if="loading" class="h-screen flex justify-center items-center">
      <div style="border-top-color:transparent"
        class="w-16 h-16 border-4 border-green-400 border-dotted rounded-full animate-spin"></div>
    </div>
    <div v-else>
      <HeroList :green_title="hero[0].hero_green_title" :black_title="hero[0].hero_black_title" :content="hero[0].hero_content" :links="hero[0].hero_links" :image="hero[0].main_image" />
      <WhatWeDo :services="services" />
      <EstimateProject />
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import HeroList from '@/components/Shared/HeroList.vue'

import WhatWeDo from '@/components/Shared/WhatWeDo.vue'
import EstimateProject from '@/components/Shared/EstimateProject.vue'

import serverAddress from '@/mixins/serverAddress';
import titleMixin from '@/mixins/titleMixin'

export default {
  title: 'Mobile application development, web apps, ux services | Flexisoft',
  name: 'ServicesList',
  mixins: [serverAddress, titleMixin],
  components: {
    HeroList,
    WhatWeDo,
    EstimateProject
  },
  data() {
    return {
      loading: true,
      services: [],
      hero: [],
    }
  },
  methods: {
    async getServicesHero() {
      const res = await fetch(this.serverAddress + '/api/v2/pages/?type=services.ServicesIndex&fields=*')
      const data = await res.json()
      return data
    },
    async getServices() {
      const res = await fetch(this.serverAddress + '/api/v2/pages/?type=services.Service&fields=*')
      const data = await res.json()
      return data
    },
  },
  async created() {
    const data = await this.getServices()
    this.services = data.items
    const hero = await this.getServicesHero()
    this.hero = hero.items

    this.loading = false

    document.querySelector('meta[name="description"]').setAttribute("content", this.services[0].meta.search_description );
  }
}
</script>

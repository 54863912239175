
<template>
  <main>
    <div class="h-screen">
      <HeroDetail green_title="Page" black_title="not found" content="This page not exist yet" :image="require(`@/assets/notfound.png`)" />
    </div> 
  </main>
</template>

<script>
import HeroDetail from '@/components/Shared/HeroDetail.vue'
import titleMixin from '@/mixins/titleMixin'
export default {
  title: 'Mobile application development company | Flexisoft',
  name: 'ContentDetail',
  mixins: [titleMixin],
  components: {
    HeroDetail
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="postcss">

.content ul, ol {
  @apply list-disc list-inside;
}
.content li {
  @apply pl-2;
}
.content h1, h2 {
  @apply text-xl font-bold dark:text-gray-200 pb-2;
}
.content p {
  @apply pb-2;
}
.content b {
  font-weight: bold;
}
.process-icons {
    font-size: 44px;
}
</style>


<template>
  <!--Header-->
    <div class="pt-12 grid justify-items-stretch">
      <!-- <div class="container px-3 mx-auto flex justify-center relative "> -->
        <div class="justify-self-center relative"> 
          <h1 class="text-3xl font-bold z-10 relative dark:text-gray-300">{{ title }}</h1>
          <span class="absolute w-120 h-3 bg-yellow-100 bottom-0.5 -ml-10 dark:bg-gray-600"></span>
      </div>
    </div>
    <!-- Card item -->
    <div class="pt-8">
      <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-start">
        <div class="flex flex-col w-full md:w-9/12 justify-center items-start">
               <p class="font-light text-dark dark:text-white" v-html="content"></p> 
        </div>
        <div class="hidden md:flex flex flex-col w-full md:w-3/12 pt-2 justify-center items-end ">
          <img :src="image" :alt="title" class="w-60 md:w-72 lg:w-72">
            <!-- <img src="@/assets/tech-stack.svg" alt="img" class="w-20 md:w-60 lg:w-60"> -->
        </div>
        <div class="flex flex-col w-full pt-4">
                <h3 class="text-mygreen text-xl font-bold dark:text-gray-300">{{ subtitle }}</h3>
                
                <div class="flex flex-col md:flex-row flex-wrap">
                    <div v-for="icon in icons" :key="icon" class="flex items-center">
                        <span class="w-2/12 material-icons-outlined text-base text-dark pr-8 dark:text-gray-300">{{ icon.value.icon }}</span>
                        <h3 class="w-10/12 my-2 text-lg  text-dark font-bold pl-2 dark:text-gray-300">
                                <span class="text-mygreen">{{ icon.value.green_header }}</span><br>{{ icon.value.black_header }}
                        </h3>
                    </div>
                    <!-- <div class="flex items-center">
                        <span class="w-2/12 material-icons-outlined text-base text-dark pr-8">phone_iphone</span>
                        <h3 class="w-10/12 my-2 text-lg  text-dark font-bold pl-2">
                                <span class="text-mygreen">Smartphones</span><br>iOS / Android
                        </h3>
                    </div>
                    <div class="flex items-center">
                        <span class="w-2/12 material-icons-outlined text-base text-dark pr-8">tablet_mac</span>
                        <h3 class="w-10/12 my-2 text-lg  text-dark font-bold pl-2">
                                <span class="text-mygreen">Tablet</span><br>iPadOS / Android 
                        </h3>
                    </div>
                    <div class="flex items-center">
                        <span class="w-2/12 material-icons-outlined text-base text-dark pr-8">watch</span>
                        <h3 class="w-10/12 my-2 text-lg  text-dark font-bold pl-2">
                                <span class="text-mygreen">Wear devices</span><br>watchOS / wear OS
                        </h3>
                    </div>
                    <div class="flex items-center">
                        <span class="w-2/12 material-icons-outlined text-base text-dark pr-8">more_horiz</span>
                        <h3 class="w-10/12 my-2 text-lg  text-dark font-bold pl-2">
                                <span class="text-mygreen">Other platforms</span><br>tvOS, macOS, desktop, etc.
                        </h3>
                    </div> -->
                    
                </div>
              </div>
      </div>

    </div>
  

</template>

<script>
export default {
  name: 'TechStack',
  props: [ 'title', 'content', 'image', 'subtitle', 'icons']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.material-icons-outlined {
    font-size: 36px;
}
</style>

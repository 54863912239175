<template>
  <main>
    <div v-if="loading" class="h-screen flex justify-center items-center">
      <div style="border-top-color:transparent"
        class="w-16 h-16 border-4 border-green-400 border-dotted rounded-full animate-spin"></div>
    </div>
    <div v-else>
      <HeroList :green_title="hero[0].hero_green_title" :black_title="hero[0].hero_black_title" :content="hero[0].hero_content" :links="hero[0].hero_links" :image="hero[0].main_image" />
      <List :portfolio_list="portfolio" />
      <EstimateProject />
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import HeroList from '@/components/Shared/HeroList.vue'
import List from '@/components/Portfolio/List.vue'
import EstimateProject from '@/components/Shared/EstimateProject.vue'

import serverAddress from '@/mixins/serverAddress';
import titleMixin from '@/mixins/titleMixin'


export default {
  title: 'Porfolio Mobile app, web development | Flexisoft',
  // description: "Our portfolio of mobile app development, web applications, ux design and more.", 
  name: 'PortfolioList',
  mixins: [serverAddress, titleMixin],
  components: {
    HeroList,
    List,
    EstimateProject
  },
  data() {
    return {
      loading: true,
      portfolio: [],
      hero: [],
    }
  },
  methods: {
    async getPortfolioHero() {
      const res = await fetch(this.serverAddress + '/api/v2/pages/?type=portfolio.PortfolioIndex&fields=*')
      const data = await res.json()
      return data
    },
    async getPortfolio() {
      const res = await fetch(this.serverAddress + '/api/v2/pages/?type=portfolio.Portfolio&fields=*')
      const data = await res.json()
      return data
    },
  },
  async created() {
    const data = await this.getPortfolio()
    this.portfolio = data.items
    const hero = await this.getPortfolioHero()
    this.hero = hero.items

    this.loading = false

    document.querySelector('meta[name="description"]').setAttribute("content", this.portfolio[0].meta.search_description );
  }
}
</script>
